import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside, Box, CoreCompetencies, JobHeader } from "../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <Box mdxType="Box">
          <center>
            <h2>{`Quantitative Finance | Software Engineering`}</h2>
            <p>{`18+ years of experience driving innovation in technology and wealth management.`}</p>
          </center>
        </Box>
        <p>{`Powerful and innovative professional with proven history of developing and delivering technology roadmap, creating long-term strategies, and engaging with cross-functional teams to build and launch unique and intuitive products. Proven ability to drive product vision through carefully balancing customer expectations with business objectives and ensuring effective use of resources. Agile in managing complex product development cycles and identifying potential areas of improvement. Adept at delivering subject matter expertise to help clients achieve financial goals. Expertise in quantitative finance, technical analysis, and automated trading. `}</p>
        <CoreCompetencies items={["Software Engineering", "Consumer Electronics", "Far-East Manufacturing", "Investment Advisory", "Quantitative Finance", "Portfolio Management"]} mdxType="CoreCompetencies" />
        <h2>{`Professional Experience`}</h2>
        <JobHeader companyName="Bertram Enterprises" companyLocation="Seattle, Washington" jobTitle="CTO, Owner" jobPeriod="2/2015 - Present" mdxType="JobHeader" />
        <p>{`Design and develop a paid membership platform for DIY investors named TuringTrader.com and drive up organic traffic and user engagement. Hold all responsibility for content development, media outreach, design and development of technical infrastructure.`}</p>
        <p><em parentName="p">{`Key Achievements:`}</em></p>
        <ul>
          <li parentName="ul">{`Owned and operated TuringTrader.com for investment-related memberships.`}</li>
          <li parentName="ul">{`Ranking for more than 500 keywords on Google and positioned in page-1 rankings in three months.`}</li>
        </ul>
        <JobHeader companyName="Bertram Solutions" companyLocation="Seattle, Washington" jobTitle="CEO, Investment Adviser" jobPeriod="11/2012 – Present" mdxType="JobHeader" />
        <p>{`Research and develop automated investment solutions to help clients utilize quantitative analysis methods for investments and create scalable business models to make individualized investment portfolios available to retail clients. Construct client portfolios based on technical and quantitative analysis to reduce investment volatility and risk while increasing returns. Mentor and train investors on the concepts of quantitative analysis, portfolio management, and development of custom investment strategies.`}</p>
        <p><em parentName="p">{`Key Achievements:`}</em></p>
        <ul>
          <li parentName="ul">{`Constructed proprietary infrastructure, allowing active trading in separately managed client accounts.`}</li>
          <li parentName="ul">{`Created individualized model portfolios based on quantitative analysis and tactical asset allocation.`}</li>
        </ul>
        <JobHeader companyName="Native Instruments" companyLocation="Berlin, Germany/ Los Angeles, California" jobTitle="Vice President of Engineering" jobPeriod="12/2012 – 2/2015" mdxType="JobHeader" />
        <p>{`Oversaw $10M software/hardware development budget, defined, sourced, negotiated, managed, and participated to ensure timely completion of projects. Led a 100-member global hardware and software development team to deliver world-class premium products. Introduced new tools to manage bills of materials and improve accuracy of early manufacturing cost estimates. Negotiated product features with product managers, based on engineering estimates for costs and schedule. Managed resource allocation of the engineering and production teams to achieve company goals. `}</p>
        <p><em parentName="p">{`Key Achievements:`}</em></p>
        <ul>
          <li parentName="ul">{`Introduced a project life cycle process to improve business planning and project execution. `}</li>
          <li parentName="ul">{`Delivered over 20 projects on schedule and within budget.`}</li>
        </ul>
        <JobHeader companyName="Audyssey Laboratories" companyLocation="Los Angeles, California" jobTitle="VP, Hardware Engineering and Global Operations" jobPeriod="8/2010 – 11/2012" mdxType="JobHeader" />
        <p>{`Led a team of 15 personnel, including 6 in Engineering, 6 in Operations and 3 in QA. Developed continuous improvement processes for product development, quality control and production. Planned and executed engineering strategies and procedures to assure projects were completed within budgetary and time constraints. Managed far-east manufacturing operations and third-party logistics.`}</p>
        <p><em parentName="p">{`Key Achievements:`}</em></p>
        <ul>
          <li parentName="ul">{`Introduced agile methods to enhance task-orientation, prioritization, and awareness of all team activities.`}</li>
          <li parentName="ul">{`Successfully delivered four innovative and premium speaker products.`}</li>
        </ul>
        <h3>{`Prior Experience:`}</h3>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Director, Hardware Development`}</strong>{` (2006 to 2010), Avid Technology/ M-Audio, Irwindale, California `}</li>
          <li parentName="ul"><strong parentName="li">{`Chief Hardware Architect`}</strong>{` (2002 to 2006), Apple Computer, Rellingen, Germany`}</li>
        </ul>
        <h2>{`Education and Credentials`}</h2>
        <p><strong parentName="p">{`Series-65`}</strong>{`, Uniform Investment Adviser Law Exam (2017)`}</p>
        <p><strong parentName="p">{`Master of Business Administration`}</strong>{` (2008)`}<br />{`
Europäische Fernhochschule, Hamburg, Germany`}</p>
        <p><strong parentName="p">{`MS Computer Science`}</strong><br />{`
Technische Universität Hamburg- Harburg, Germany`}</p>
        <p><strong parentName="p">{`BS Electrical Engineering`}</strong><br />{`
Technische Universität Braunschweig, Germany`}</p>
      </Main>
      <Aside mdxType="Aside">
        <p>{`Current residence: `}<a parentName="p" {...{
            "href": "https://www.google.com/maps/place/Seattle,+WA/@47.6131746,-122.4821474,11z/data=!3m1!4b1!4m5!3m4!1s0x5490102c93e83355:0x102565466944d59a!8m2!3d47.6062095!4d-122.3320708"
          }}>{`Seattle, USA`}</a>{`.`}</p>
        <p>{`Further info:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.linkedin.com/in/felixbertram"
            }}>{`LinkedIn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "mailto:flx@bertram-family.com?subject=Resume%22"
            }}>{`email me`}</a></li>
        </ul>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      